<template>
  <div class="business-register-page">
    <MetaManager
      title="Register your business"
      description="Register your business today to start selling on our platform. Join a community of entrepreneurs and unlock new opportunities for growth and success."
    />
    <div class="container">
      <div class="header animate-on-scroll" data-animation="fade-in-up">
        <h1>Promote Your Business with Our Platform</h1>
        <p>Reach New Customers. Build Loyalty.</p>
        <p>Get Started Today with $0 Upfront Costs!</p>
      </div>
      <div class="content">
        <div class="form-image-container animate-on-scroll" data-animation="fade-in-up">
          <div class="form-container">
            <h2 class="form-title">Sign Up</h2>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label for="businessName">Business Name:</label>
                <input type="text" id="businessName" v-model="form.businessName" required />
              </div>
              <div class="form-group">
                <label for="businessAddress">Business Address:</label>
                <input type="text" id="businessAddress" v-model="form.businessAddress" required />
              </div>
              <div class="form-group">
                <label for="name">First & Last Name:</label>
                <input type="text" id="name" v-model="form.name" required />
              </div>
              <div class="form-group">
                <label for="email">Email Address:</label>
                <input type="email" id="email" v-model="form.email" required />
              </div>
              <div class="form-group">
                <label for="phone">Phone Number:</label>
                <input type="tel" id="phone" v-model="form.phone" required />
              </div>
              <div class="form-group">
                <label for="businessType">What category best describes your business:</label>
                <select id="businessType" v-model="form.businessType" required>
                  <option disabled value="">Choose a category</option>
                  <option>Retail</option>
                  <option>Restuarant</option>
                  <option>Beauty & Spa</option>
                  <option>Things to do</option>
                  <option>Health & Fitness</option>
                  <option>Automotive</option>
                  <option>Technology</option>
                  <option>Home Services</option>
                  <option>Other/Not Listed</option>
                </select>
              </div>
              <div class="form-group terms">
                <input type="checkbox" id="terms" v-model="form.terms" required />
                <label for="terms" required>
                  By clicking above, I agree to the
                  <a href="/terms-of-service">Terms of Service</a> and that I have read the
                  <a href="/privacy-policy">Privacy Policy</a>.
                </label>
              </div>
              <button type="submit" class="sign-up-button">Sign Up</button>
              <p v-if="showSubmissionMessage" class="submission-message" :class="{ 'success': submissionMessage.includes('successfully'), 'error': submissionMessage.includes('error') }">
                {{ submissionMessage }}
              </p>
            </form>
          </div>
          <div class="image-container">
            <img src="@/assets/31982116_9611.jpg" alt="Business Promotion" />
          </div>
        </div>
        <div class="info-sections">
          <div class="info-section animate-on-scroll" data-animation="fade-in-right">
            <div class="info-content">
              <h2>Gain New Customers and Strengthen Brand Loyalty</h2>
              <p>You offer your customers products that they enjoy, why not provide them with a platform to find discounts on your products or services while also offering them discounted gift cards that they can use at your stores. We use cutting-edge technology to offer the right items to your customers. Our tools will help you attract customers giving them a good impression of your brand.</p>
            </div>
            <img src="@/assets/Success of startup managers working near growth of profit chart.jpg" alt="Business Engagement" />
          </div>
          <div class="stats-section animate-on-scroll" data-animation="fade-in-left">
            <div class="stat">
              <h2>Join Our Platform Today!</h2>
            </div>
            <div class="stat-details">
              <div>
                <p><strong>Secondary</strong></p>
                <p>Sales Funnel</p>
              </div>
              <div>
                <p><strong>Customized</strong></p>
                <p>Merchant Dashboard</p>
              </div>
              <div>
                <p><strong>Unlimited</strong></p>
                <p>Growth Potential</p>
              </div>
            </div>
          </div>
          <div class="info-box animate-on-scroll" data-animation="fade-in-up">
            <h2>Our Platform Is The Marketplace For Local Businesses</h2>
            <p>Our platform is more than a place to shop for incentives or gift cards. It's where customers can go to get more info about your business or service that you offer. Here's what we do for you:</p>
            <div class="feature-cards">
              <div v-for="(feature, index) in features" :key="index" class="feature-card">
                <img :src="feature.icon" :alt="feature.title" class="feature-icon">
                <h3>{{ feature.title }}</h3>
                <p>{{ feature.description }}</p>
              </div>
            </div>
          </div>
          <div class="cta-section animate-on-scroll" data-animation="fade-in-up">
            <h2>Like what you see?</h2>
            <button @click="scrollToTop" class="cta-button">Get Started</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';
export default {
  name: "BusinessRegisterPage",
  components:{
    MetaManager
  },
  data() {
    return {
      form: {
        businessName: "",
        businessAddress: "",
        name: "",
        email: "",
        phone: "",
        businessType: "",
        terms: false,
      },
      submissionMessage: '',
      showSubmissionMessage: false,
      features: [
        {
          title: "Find New Audiences",
          description: "We get important info from your users to show them products that are relevant to them, so you get the right customers to your products.",
          icon: "https://img.icons8.com/color/48/000000/crowd.png"
        },
        {
          title: "Boost Brand",
          description: "With our advertising, we target customers that are relevant to your products, so your marketing dollars are spent on customers that are going to buy.",
          icon: "https://img.icons8.com/color/48/000000/bullish.png"
        },
        {
          title: "Customizable Business Page",
          description: "We give you a Customizable page to showcase your business. Show sales flyers, posts about business updates, event calendars, or menus.",
          icon: "https://img.icons8.com/color/48/000000/edit-property.png"
        },
        {
          title: "Track Metrics",
          description: "Easily see how you are doing month over month on gift card sales in our easy to use dashboard. Get paid when you need it.",
          icon: "https://img.icons8.com/color/48/000000/combo-chart.png"
        },
        {
          title: "Communication that matters",
          description: "Easily message with your customers to have a 1 on 1 interaction with them to handle requests or issues quickly.",
          icon: "https://img.icons8.com/color/48/000000/communication.png"
        },
        {
          title: "Send notifications",
          description: "Send notifications to customers on the site, via email, or right to their phone. Running a special? Great send out a text to customers.",
          icon: "https://img.icons8.com/color/48/000000/appointment-reminders.png"
        },
      ]
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add(entry.target.dataset.animation);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach(element => {
      observer.observe(element);
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    async submitForm() {
      // Validate required fields
      if (!this.form.businessName || !this.form.businessAddress || !this.form.name || !this.form.email || !this.form.phone || !this.form.businessType || !this.form.terms) {
        this.submissionMessage = 'All fields are required';
        this.showSubmissionMessage = true;
        setTimeout(() => {
          this.showSubmissionMessage = false;
        }, 5000);
        return;
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/business-signup`, this.form);
        
        // Clear the form
        this.clearForm();
        
        // Show submission message
        this.submissionMessage = 'Form submitted successfully!';
        this.showSubmissionMessage = true;
        
        // Hide the message after 5 seconds
        setTimeout(() => {
          this.showSubmissionMessage = false;
        }, 5000);
      } catch (error) {
        console.error('Error submitting form', error);
        this.submissionMessage = 'An error occurred. Please try again.';
        this.showSubmissionMessage = true;
        
        // Hide the error message after 5 seconds
        setTimeout(() => {
          this.showSubmissionMessage = false;
        }, 5000);
      }
    },
    clearForm() {
      this.form = {
        businessName: "",
        businessAddress: "",
        name: "",
        email: "",
        phone: "",
        businessType: "",
        terms: false,
      };
    },
  },
};
</script>

<style scoped>
@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInRight {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInLeft {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}

.fade-in-up { opacity: 0; animation: fadeInUp 1s forwards; }
.fade-in-right { opacity: 0; animation: fadeInRight 1s forwards; }
.fade-in-left { opacity: 0; animation: fadeInLeft 1s forwards; }

body { background-color: white; }

.business-register-page {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  padding: 20px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 12px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

.header p {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.form-container, .image-container {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.form-container {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
}

form { display: flex; flex-direction: column; }

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.form-group input,
.form-group select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group select {
  appearance: none;
  background: #fff url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23333"><path d="M1 3.5l7 7 7-7"/></svg>') no-repeat right 10px center;
  background-size: 12px;
}

.form-group.terms {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.form-group.terms input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 3px;
}

.form-group.terms label {
  flex: 1;
  font-size: 14px;
  line-height: 1.4;
}

.sign-up-button {
  padding: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.sign-up-button:hover { background-color: #6E9F49; }

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.info-sections { width: 100%; }

.info-section {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-section img {
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.info-content { width: 100%; }

.info-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.info-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.stats-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #4C6B30 0%, #5f8a3c 100%);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  color: white;
  position: relative;
  overflow: hidden;
}

.stat {
  text-align: center;
  z-index: 1;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.stat-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.stat-details div {
  flex: 1 1 100%;
  margin-bottom: 15px;
}

.stat h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #fff;
}

.stat p, .stat-details p {
  font-size: 16px;
  color: #fff;
  margin: 5px 0;
}

.info-box {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.feature-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;
}

.feature-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
}

.feature-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

a {
  color: #00796b;
  text-decoration: underline;
}

a:hover { text-decoration: none; }

.cta-section {
  text-align: center;
  margin-top: 30px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.cta-section h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.cta-button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background-color: #4C6B30;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover { background-color: #6E9F49; }

.submission-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.submission-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.submission-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}



@media (min-width: 768px) {
  .business-register-page { padding: 40px 0; }
  .container { padding: 40px; }
  .header h1 { font-size: 36px; }
  .header p { font-size: 20px; }
  
  .form-image-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .form-container, .image-container { width: 45%; }
  
  .info-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .info-content { width: 45%; }
  .info-section img { width: 50%; margin-bottom: 0; }
  
  .stats-section { flex-direction: row; padding: 30px; }
  .stat { width: 30%; margin-bottom: 0; }
  .stat-details div { flex: 1 1 33%; }
  
  .feature-cards { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1024px) {
  .feature-cards { grid-template-columns: repeat(3, 1fr); }

}


</style>
