<template>
  <div class="step-options">
    <h2>Step 2: Options</h2>
    <div class="form-container">
      <div class="option-form">
        <form @submit.prevent="handleSubmit">
          <!-- Existing form inputs -->
          <div class="form-group">
            <label for="dealName">Option Name</label>
            <input type="text" id="dealName" v-model="currentOption.dealName" maxlength="120" @input="validateForm" required />
            <small>{{ 120 - currentOption.dealName.length }} Characters remaining</small>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="releaseAmount">Release Amount</label>
              <input type="number" id="releaseAmount" v-model.number="currentOption.releaseAmount" @input="validateForm" />
            </div>
            <div class="form-group">
              <label for="releaseFrequency">Release Frequency</label>
              <select id="releaseFrequency" v-model="currentOption.releaseFrequency" @input="validateForm">
                <option value="" disabled>Select Frequency</option>
                <option value="none">None</option>
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="currentPrice">Card Amount ($)</label>
              <input type="number" id="currentPrice" v-model="currentOption.currentPrice" min="1" max="999" step="0.01" @input="validateForm" required />
              <small>Card Amount should be between $1 and $999</small>
            </div>
            <div class="form-group">
              <label for="numberInStock">Number in stock</label>
              <input type="number" id="numberInStock" v-model.number="currentOption.numberInStock" @input="validateForm" required />
            </div>
          </div>
          <div class="form-group">
            <label for="maxPerPurchase">Max Purchase Amount (per customer)</label>
            <input type="number" id="maxPerPurchase" v-model.number="currentOption.maxPerPurchase" @input="validateForm" />
          </div>
          <div class="form-group">
            <label for="giftCardCode">Gift Card Code</label>
            <div class="coupon-code-container">
              <input type="text" id="giftCardCode" v-model="currentOption.giftCardCode" maxlength="50" @input="validateForm" readonly />
              <button type="button" @click="generateCodes">Generate Codes</button>
              <button type="button" v-if="codesGenerated" @click="toggleShowGiftCards">{{ showGiftCards ? 'Hide Gift Cards' : 'Show Gift Cards' }}</button>
            </div>
            <div v-if="showGiftCards" class="gift-cards-table-container">
              <h3>Gift Card Codes</h3>
              <table class="gift-cards-table">
                <thead>
                  <tr>
                    <th>Gift Card Code</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(code, index) in generatedCodes" :key="index">
                    <td>{{ formatCode(code) }}</td>
                    <td>
                      <button @click="editCode(index)">Edit</button>
                      <button @click="deleteCode(index)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group">
            <label for="discountAmount">What Customer Pays ($)</label>
            <input
              type="number"
              id="discountAmount"
              v-model.number="currentOption.discountAmount"
              min="0"
              step="0.01"
              @input="calculateDiscountPercentage"
            />
            <small>Enter the discount amount in dollars. This will automatically calculate the discount percentage.</small>
          </div>
          <small>or enter percentage</small>
          <br>
          <div class="form-group">
            <label for="discountInput">Discount (%)</label>
            <input
              type="number"
              id="discountInput"
              v-model.number="currentOption.discount"
              min="0"
              max="100"
              step="0.01"
              @input="validateForm"
              required
            />
            <small>Discount should be between 0% and 100%.</small>
          </div>
          <div class="form-group">
            <label for="cutBetweenBusinesses">Cut Between Businesses (%)</label>
            <input type="number" id="cutBetweenBusinesses" v-model.number="currentOption.businessCut" min="0" max="100" step="1" @input="validateForm" required />
            <small>The percentage of the total amount the business receives</small>
          </div>
          <div class="price-calculations">
            <div class="calculation">
              <div class="label">Customer Pays</div>
              <div class="value">${{ calculateCustomerPays(currentOption) }}</div>
            </div>
            <div class="calculation">
              <div class="label">Business Gets</div>
              <div class="value">${{ calculateYouGet(currentOption) }}</div>
            </div>
            <div class="calculation">
              <div class="label">Trio Tech Gets</div>
              <div class="value">${{ calculateShopSharkGets(currentOption) }}</div>
            </div>
          </div>
          <div class="button-group">
            <button type="submit" :disabled="!isFormValid || !codesGenerated">{{ editingIndex === null ? 'Add Option' : 'Update Option' }}</button>
            <button type="button" @click="clearForm">Clear Form</button>
          </div>
        </form>
      </div>
      <div class="options-list">
        <h3>Created Options</h3>
        <ul>
          <li v-for="(option, index) in savedOptions" :key="index">
            <div class="option-summary">
              <strong>{{ option.dealName }}</strong> - ${{ option.currentPrice }}
            </div>
            <div class="option-actions">
              <button @click="editOption(index)">Edit</button>
              <button @click="deleteOption(index)">Delete</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
  name: 'StepOptions',
  props: {
    currentStep: Number,
  },
  setup() {
    const currentOption = ref({
      dealName: '',
      currentPrice: 0,
      numberInStock: 0,
      discount: 20,
      discountAmount: 0,
      businessCut: 50,
      giftCardCode: '',
      maxPerPurchase: 0,
      releaseAmount: 0,
      releaseFrequency: 'none',
      exactDate: ''
    });
    const isFormValid = ref(false);
    const savedOptions = ref([]);
    const editingIndex = ref(null);
    const codesGenerated = ref(false);
    const showGiftCards = ref(false);
    const generatedCodes = ref([]);

    const validateForm = () => {
      const option = currentOption.value;
      isFormValid.value =
        option.dealName &&
        option.currentPrice > 0 &&
        option.currentPrice <= 999 &&
        option.numberInStock > 0 &&
        option.discount >= 0 &&
        option.discount <= 100 &&
        option.businessCut >= 0 &&
        option.businessCut <= 100 &&
        option.maxPerPurchase >= 0 &&
        option.releaseAmount >= 0 &&
        option.releaseFrequency &&
        codesGenerated.value; // Ensure codes are generated
    };

    const handleSubmit = () => {
      if (editingIndex.value !== null) {
        // Update existing option
        savedOptions.value[editingIndex.value] = { ...currentOption.value };
        editingIndex.value = null;
      } else {
        // Add new option
        savedOptions.value.push({ ...currentOption.value });
      }
      saveOptions();
      clearForm();
      updateHasOptions();
    };

    const clearForm = () => {
      currentOption.value = {
        dealName: '',
        currentPrice: 0,
        numberInStock: 0,
        discount: 20,
        businessCut: 50,
        giftCardCodes: '',
        maxPerPurchase: 0,
        releaseAmount: 0,
        releaseFrequency: 'none',
        exactDate: ''
      };
      codesGenerated.value = false;
      showGiftCards.value = false;
      generatedCodes.value = [];
      editingIndex.value = null;
      validateForm();
    };

    const editOption = (index) => {
      currentOption.value = { ...savedOptions.value[index] };
      editingIndex.value = index;
      generatedCodes.value = [...currentOption.value.giftCardCodes]; // Load the gift card codes
      codesGenerated.value = true;
      showGiftCards.value = true;
      validateForm();
    };

    const deleteOption = (index) => {
      savedOptions.value.splice(index, 1);
      saveOptions();
      updateHasOptions();
    };

    const saveOptions = () => {
      localStorage.setItem('savedOptions', JSON.stringify(savedOptions.value));
    };

    const calculateCustomerPays = (option) => {
      return (option.currentPrice * (1 - option.discount / 100)).toFixed(2);
    };

    const calculateYouGet = (option) => {
      return (option.currentPrice * (1 - option.discount / 100) * (option.businessCut / 100)).toFixed(2);
    };

    const calculateShopSharkGets = (option) => {
      return (option.currentPrice * (1 - option.discount / 100) * ((100 - option.businessCut) / 100)).toFixed(2);
    };

    const calculateDiscountPercentage = () => {
      const originalPrice = currentOption.value.currentPrice;
      const customerPays = currentOption.value.discountAmount;

      if (originalPrice > 0 && customerPays > 0) {
        currentOption.value.discount = (((originalPrice - customerPays) / originalPrice) * 100).toFixed(2);
      } else {
        currentOption.value.discount = 0;
      }

      validateForm();
    };

    const generateCodes = () => {
      const chars = '0123456789';
      let giftCardCode = '';
      for (let i = 0; i < 12; i++) {
        if (i > 0 && i % 4 === 0) {
          giftCardCode += '-';
        }
        giftCardCode += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      const baseCode = giftCardCode.replace(/-/g, ''); // Remove dashes from base code
      const numberInStock = currentOption.value.numberInStock;
      const currentCodesCount = generatedCodes.value.length;
      const newCodesCount = numberInStock - currentCodesCount;

      for (let i = 0; i < newCodesCount; i++) {
        let suffix = '';
        for (let j = 0; j < 4; j++) {
          suffix += Math.floor(Math.random() * 10);
        }
        const code = `${baseCode}${suffix}`;
        generatedCodes.value.push(code);
      }

      // Save the generated codes into currentOption
      currentOption.value.giftCardCodes = [...generatedCodes.value];  // Store all generated codes
      codesGenerated.value = true;
      showGiftCards.value = true;
    };

    const toggleShowGiftCards = () => {
      showGiftCards.value = !showGiftCards.value;
    };

    const formatCode = (code) => {
      return code.match(/.{1,4}/g).join('-');
    };

    const editCode = (index) => {
      const newCode = prompt('Edit Gift Card Code:', formatCode(generatedCodes.value[index]));
      if (newCode) {
        generatedCodes.value[index] = newCode.replace(/-/g, '');
      }
    };

    const deleteCode = (index) => {
      generatedCodes.value.splice(index, 1);
      currentOption.value.numberInStock -= 1; // Decrease number in stock by 1
      currentOption.value.giftCardCodes = [...generatedCodes.value]; // Update giftCardCodes in currentOption
      validateForm();
    };

    const updateHasOptions = () => {
      const event = new CustomEvent('updateHasOptions', {
        detail: JSON.parse(localStorage.getItem('savedOptions') || '[]').length > 0,
      });
      window.dispatchEvent(event);
    };

    onMounted(() => {
      const savedOptionsData = localStorage.getItem('savedOptions');
      if (savedOptionsData) {
        savedOptions.value = JSON.parse(savedOptionsData);
      }
      validateForm();
      updateHasOptions();
    });

    watch(currentOption, validateForm, { deep: true });

    return {
      currentOption,
      isFormValid,
      savedOptions,
      editingIndex,
      codesGenerated,
      showGiftCards,
      generatedCodes,
      handleSubmit,
      clearForm,
      editOption,
      deleteOption,
      calculateCustomerPays,
      calculateYouGet,
      calculateShopSharkGets,
      validateForm,
      generateCodes,
      toggleShowGiftCards,
      formatCode,
      editCode,
      deleteCode,
      calculateDiscountPercentage
    };
  },
};
</script>

<style scoped>
.step-options {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  justify-content: space-between;
}

.option-form {
  width: 60%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.options-list {
  width: 35%;
  padding-left: 20px;
  border-left: 1px solid #ccc;
}

.form-group {
  margin-bottom: 15px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.form-row .form-group {
  flex: 1 1 calc(50% - 20px);
  margin: 0 10px 15px;
  min-width: 200px;
}

.coupon-code-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

small {
  display: block;
  margin-top: 5px;
  color: #888;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.price-calculations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.calculation {
  text-align: center;
  flex: 1 1 30%;
  margin-bottom: 15px;
}

.calculation .label {
  font-size: 1em;
  font-weight: bold;
}

calculation .value {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.gift-cards-table-container {
  overflow-x: auto;
}

.gift-cards-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.gift-cards-table th,
.gift-cards-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.gift-cards-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.gift-cards-table td {
  text-align: center;
}

.gift-cards-table button {
  margin-left: 10px;
}

.options-list ul {
  list-style-type: none;
  padding: 0;
}

.options-list li {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}

.option-summary {
  margin-bottom: 5px;
}

.option-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media (max-width: 1024px) {
  .form-container {
    flex-direction: column;
  }

  .option-form,
  .options-list {
    width: 100%;
  }

  .options-list {
    margin-top: 20px;
    padding-left: 0;
    border-left: none;
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .form-row .form-group {
    flex: 1 1 100%;
  }

  .coupon-code-container {
    flex-direction: column;
  }

  .coupon-code-container button {
    width: 100%;
  }

  .price-calculations {
    flex-direction: column;
  }

  .calculation {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .step-options {
    padding: 0 10px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea {
    padding: 8px;
  }

  .calculation .value {
    font-size: 1.2em;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
  }
}
</style>