<template>
  <div id="app">
    <!-- Loading Spinner -->
    <LoadingSpinner v-if="loading" />

    <!-- Conditional rendering of NavBar or DashboardSidebar based on the current route -->
    <component :is="currentSidebar" :shoppingBagItems="shoppingBagItems" @update-shopping-bag="updateShoppingBag" v-show="!loading" />
    <router-view @add-to-shopping-bag="addToShoppingBag" v-show="!loading" />

    <!-- Conditional rendering of Footer based on the current route -->
    <Footer v-if="!isDashboardRoute && !loading" />

    <!-- Scroll to Top Button -->
    <button v-if="showScrollToTop" @click="scrollToTop" class="scroll-to-top">↑</button>
  </div>
</template>

<script>
import NavBar from './components/Frontend Nav Components/NavBar.vue';
import DashboardSidebar from './components/Dashboard Nav Components/DashboardSidebar.vue';
import Footer from './components/Frontend Nav Components/FooterBar.vue';
import LoadingSpinner from './components/LoadingSpinner.vue';


export default {
  name: 'App',
  components: {
    NavBar,
    DashboardSidebar,
    Footer,
    LoadingSpinner
  },
  computed: {
    currentSidebar() {
      return this.isDashboardRoute ? DashboardSidebar : NavBar;
    },
    isDashboardRoute() {
      return this.$route.path.startsWith('/dashboard');
    }
  },
  data() {
    return {
      shoppingBagItems: JSON.parse(localStorage.getItem('shoppingBagItems')) || [],
      loading: true,
      tawkToScript: null,
      showScrollToTop: false
    };
  },
  methods: {
    addToShoppingBag(item) {
      const shoppingBagItems = [...this.shoppingBagItems, item];
      this.shoppingBagItems = shoppingBagItems;
      localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
    },
    updateShoppingBag(items) {
      this.shoppingBagItems = items;
      localStorage.setItem('shoppingBagItems', JSON.stringify(items));
    },
    initializeTawkTo() {
      if (!this.isDashboardRoute && !document.getElementById('tawkto-script')) {
        var s1 = document.createElement("script");
        s1.async = true;
        s1.src = 'https://embed.tawk.to/672a11024304e3196add7415/1ibu54s5q';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s1.id = 'tawkto-script';
        document.head.appendChild(s1);
        this.tawkToScript = s1;
      }
    },
    removeTawkTo() {
      // Remove the script tag
      if (this.tawkToScript) {
        this.tawkToScript.remove();
        this.tawkToScript = null;
      }
      
      // Remove the Tawk_API elements
      const tawkToElements = document.querySelectorAll('iframe[title*="chat"]');
      tawkToElements.forEach(element => element.remove());
      
      // Clean up the global Tawk_API object
      if (window.Tawk_API) {
        if (typeof window.Tawk_API.hideWidget === 'function') {
          window.Tawk_API.hideWidget();
        }
        // Remove the global variables
        delete window.Tawk_API;
        delete window.Tawk_LoadStart;
      }
    },
    handleRouteChange() {
      if (this.isDashboardRoute) {
        this.removeTawkTo();
      } else {
        this.initializeTawkTo();
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200;
    }
  },
  watch: {
    shoppingBagItems(newItems) {
      this.$emit('update-shopping-bag', newItems);
    },
    '$route': {
      handler() {
        this.handleRouteChange();
      },
      immediate: true
    }
  },
  mounted() {
    const currentHostname = window.location.hostname;
    if(currentHostname.includes('summitshopper.com')){
      localStorage.setItem('selectedLocation', 'Summit County, Ohio');
    } else if(currentHostname.includes('starkshopper.com')){
      localStorage.setItem('selectedLocation', 'Stark County, Ohio');
    }

    setTimeout(() => {
      this.loading = false;
    }, 700);

    // Initialize Tawk.to only if not on dashboard route
    if (!this.isDashboardRoute) {
      this.initializeTawkTo();
    }

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    // Cleanup Tawk.to when component is destroyed
    this.removeTawkTo();
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
#app {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: 'Roboto', sans-serif;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}
</style>