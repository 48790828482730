<template>
    <Transition name="fade" appear>
      <div v-if="isVisible" class="popup-overlay">
        <Transition name="popup" appear>
          <div v-if="isVisible" class="popup-container">
            <div class="popup-header">
              <img class="popup-logo" src="@/assets/logo.png" alt="Stark Shopper Logo" />
            </div>
            <div class="popup-content">
              <h2>Welcome, Stark Shopper!</h2>
              <p>
                Join today to enjoy discounted gift cards and incentives from your favorite local businesses.
              </p>
              <ul class="benefits-list">
                <li><i class="fas fa-check-circle"></i> Get incentives to local area businesses</li>
                <li><i class="fas fa-check-circle"></i> Discounted gift cards to your favorite businesses</li>
                <li><i class="fas fa-check-circle"></i> Support local businesses</li>
                <li><i class="fas fa-check-circle"></i> Become a fan of businesses for exclusive alerts</li>
                <li><i class="fas fa-check-circle"></i> Save incentives for later and access them easily</li>
                <li><i class="fas fa-check-circle"></i> Easily access gift cards on any device</li>
                <li><i class="fas fa-check-circle"></i> No membership fees, ever!</li>
              </ul>
            </div>
            <div class="popup-footer">
              <button class="btn-join" @click="joinNow">Join Now</button>
              <button class="btn-no-thanks" @click="closePopup">No Thanks</button>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    setup() {
      const isVisible = ref(true); // Initially set to true for testing
      const router = useRouter();
  
      const closePopup = () => {
        isVisible.value = false;
        localStorage.setItem('popupShown', 'true'); // Prevent it from showing again
      };
  
      const joinNow = () => {
        localStorage.setItem('popupShown', 'true'); // Mark as shown
        router.push('/register'); // Redirect to register page
      };
  
      return {
        isVisible,
        closePopup,
        joinNow,
      };
    },
  };
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Ensure it is on top of all other content */
  }
  
  .popup-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow */
    color: #333;
  }
  
  .popup-header {
    margin-bottom: 15px;
  }
  
  .popup-logo {
    width: 100px;
    margin: auto;
  }
  
  .popup-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .popup-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #555;
  }
  
  .benefits-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    text-align: left;
  }
  
  .benefits-list li {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #444;
    display: flex;
    align-items: center;
  }
  
  .benefits-list li i {
    color: #28a745;
    margin-right: 10px;
  }
  
  .popup-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .btn-join {
    background-color: #28a745;
    color: white;
    border: none;
    width: 45%;
    padding: 12px 0;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn-join:hover {
    background-color: #218838;
  }
  
  .btn-no-thanks {
    background-color: #6c757d;
    color: white;
    border: none;
    width: 45%;
    padding: 12px 0;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn-no-thanks:hover {
    background-color: #5a6268;
  }
  
  /* Animation styles */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  .popup-enter-active,
  .popup-leave-active {
    transition: all 0.3s ease;
  }
  
  .popup-enter-from,
  .popup-leave-to {
    transform: scale(0.9);
    opacity: 0;
  }
  
  @media (max-width: 768px) {
    .popup-container {
      width: 80%; /* Make it more responsive */
    }
  
    .popup-content h2 {
      font-size: 1.5rem;
    }
  
    .btn-join, .btn-no-thanks {
      width: 48%;
    }
  }
  </style>