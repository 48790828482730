<template>
  <div class="orders-section">
    <h2>Gift Cards</h2>
    <div v-for="order in paginatedOrders" :key="order.orderId" class="order-card">
      <div class="order-details">
        <div class="order-header">
          <div class="order-info">
            <div class="order-info-row">
              <span class="order-label">Order number:</span>
              <span class="order-value">{{ order.order_id }}</span>
            </div>
            <div class="order-info-row">
              <span class="order-label">Businesses:</span>
              <span class="order-value">{{ [...new Set(order.items.map(item => item.businessName))].join(', ') }}</span>
            </div>
            <div class="order-info-row">
              <span class="order-label">Order date:</span>
              <span class="order-value">{{ formatDate(order.created_at) }}</span>
            </div>
            <div class="order-info-row">
              <span class="order-label">Total:</span>
              <span class="order-value">${{ order.total_cost }}</span>
            </div>
            <div class="order-info-row">
              <span class="order-label">Status:</span>
              <span class="order-value" :class="order.status">{{ order.status }}</span>
            </div>
          </div>
          <button class="order-action-button" @click="toggleOrderDetails(order)">
            {{ order.showDetails ? 'Hide Gift Card Codes' : 'View Gift Card Codes' }}
          </button>
        </div>
        <div v-if="order.showDetails" class="order-items">
          <div v-for="item in order.items" :key="item.dealTitle" class="order-item">
            <div class="item-details">
              <span class="item-title">{{ item.businessName }}: {{ item.dealTitle }} </span>
              <span class="item-quantity">Quantity: {{ item.quantity }}</span>
              <div v-if="item.giftCardCodes && item.giftCardCodes.length" class="gift-card-codes">
              <div v-for="(code, index) in item.giftCardCodes" :key="index" class="gift-card-code">
                <button 
                  @click="showRedemptionOptions(code, order.order_id, item.dealTitle, item.giftCardId, item.businessName)" 
                  :disabled="item.redeemed">
                  {{ item.redeemed ? 'Redeemed' : `View Gift Card Code` }}
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="hasMoreOrders" class="load-more-button" @click="loadMoreOrders">Load More</button>

    <!-- Redemption Options Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h3>Select Redemption Option</h3>
        <div class="button-container">
          <button @click="redeemOnline">Gift Card Details</button>
          <button @click="redeemInStore">Redeem In-Store</button>
          <button @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>

    <div v-if="showRedeemInStoreModal" class="modal">
      <div class="modal-content redeem-modal">
        <h3 class="modal-title">Gift Card Redemption</h3>
        <div class="modal-section">
          <p><strong>Business:</strong> {{ selectedBusinessName }}</p>
          <p><strong>Gift Card Amount:</strong> {{ selectedDealTitle }}</p>
        </div>
        <div class="barcode-container">
          <svg id="barcode"></svg>
        </div>
        <div class="button-group">
          <button class="action-button" @click="showConfirmModal">Confirm Use</button>
          <button class="secondary-button" @click="goToFinePrint(selectedGiftCardId)">Gift Card Details</button>
          <button class="close-button" @click="closeModal">Close</button>
        </div>
      </div>
    </div>

    <!-- Verification Modal -->
    <div v-if="showConfirmUseModal" class="modal">
      <div class="modal-content">
        <h3>Confirm Use In-Store</h3>
        <p>Are you sure you want to use this gift card in-store? <strong>Once confirmed, the card can't be reused.</strong></p>
        <button @click="confirmInStoreUse">Yes, Confirm</button>
        <button @click="closeModal">Cancel</button>
      </div>
    </div>

    <!-- Display Gift Card Code Modal with Barcode and Fine Print Button -->
    <div v-if="showCodeModal" class="modal">
      <div class="modal-content code-modal">
        <h3 class="modal-title">Gift Card Details</h3>
        <div class="modal-section">
          <p><strong>Business:</strong> {{ selectedBusinessName }}</p>
          <p><strong>Gift Card Amount:</strong> {{ selectedDealTitle }}</p>
        </div>
        <div class="barcode-container">
          <svg id="barcode"></svg>
        </div>
        <div class="button-group">
          <button class="action-button" @click="copyCode(formattedGiftCardCode)">Copy Code</button>
          <button class="secondary-button" @click="goToFinePrint(selectedGiftCardId)">Gift Card Details</button>
          <button class="close-button" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import JsBarcode from 'jsbarcode';

export default {
  name: 'OrdersPage',
  data() {
    return {
      orders: [],
      paginatedOrders: [],
      itemsPerPage: 5,
      currentPage: 1,
      showModal: false,
      showVerificationModal: false,
      showCodeModal: false,
      selectedGiftCardCode: '',
      formattedGiftCardCode: '',
      selectedOrderNumber: null,
      selectedDealTitle: null,
      selectedBusinessName: null,
      selectedGiftCardId: null,
      showConfirmUseModal: false,
      showRedeemInStoreModal: false,
    };
  },
  mounted() {
    this.fetchOrders();
  },
  computed: {
    hasMoreOrders() {
      return this.paginatedOrders.length < this.orders.length;
    }
  },
  methods: {
    async fetchOrders() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/orders/get-orders/${user.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        console.log('Orders response:', response.data);

        this.orders = response.data.map(order => {
          if (typeof order.items === 'string') {
            order.items = JSON.parse(order.items); // Parse only if it's a string
          }
          return order;
        }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        
        this.updatePaginatedOrders();
      } catch (error) {
        console.error('Error fetching orders:', error);
        alert('Error fetching orders');
      }
    },
    updatePaginatedOrders() {
      const end = this.currentPage * this.itemsPerPage;
      this.paginatedOrders = this.orders.slice(0, end);
    },
    loadMoreOrders() {
      this.currentPage++;
      this.updatePaginatedOrders();
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    toggleOrderDetails(order) {
      order.showDetails = !order.showDetails;
    },
    formatGiftCardCode(code) {
      if (!code) return '';
      return code.match(/.{1,4}/g).join('-');
    },
    copyCode(formattedCode) {
      navigator.clipboard.writeText(formattedCode).then(() => {
        alert('Gift card code copied to clipboard!');
      });
    },
    showRedemptionOptions(giftCardCode, orderNumber, dealTitle, giftCardId, businessName) {
      this.selectedGiftCardCode = giftCardCode;
      this.selectedOrderNumber = orderNumber;
      this.selectedDealTitle = dealTitle;
      this.selectedGiftCardId = giftCardId;
      this.selectedBusinessName = businessName;
      this.showModal = true;

      // Generate barcode for the selected gift card code
      this.$nextTick(() => {
        this.generateBarcode(giftCardCode);
      });
    },
    redeemOnline() {
      this.formattedGiftCardCode = this.formatGiftCardCode(this.selectedGiftCardCode);
      this.showModal = false;
      this.showCodeModal = true;

      // Wait for the DOM to fully render the modal, then generate the barcode
      this.$nextTick(() => {
        this.generateBarcode(this.selectedGiftCardCode);
      });
    },
    generateBarcode(code) {
      const barcodeElement = document.getElementById('barcode');
      
      if (barcodeElement) {
        JsBarcode(barcodeElement, code, {
          format: "CODE128",
          displayValue: true,
          fontSize: 18,
          textMargin: 5,
          width: 2,
          height: 100
        });
      } else {
        console.error('Barcode element not found.');
      }
    },
    goToFinePrint(giftCardId) {
      this.$router.push(`/gift-card/${giftCardId}`);
    },
    redeemInStore() {
      this.formattedGiftCardCode = this.formatGiftCardCode(this.selectedGiftCardCode);
      this.showModal = false;
      this.showRedeemInStoreModal = true; // Open the barcode modal for redeem in-store

      // Ensure barcode generation happens after modal opens
      this.$nextTick(() => {
        this.generateBarcode(this.selectedGiftCardCode);
      });
    },
    showConfirmModal() {
      this.showRedeemInStoreModal = false; // Close barcode modal
      this.showConfirmUseModal = true; // Open confirmation modal
    },
    async confirmInStoreUse() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/orders/redeem-gift-card`, {
          orderNumber: this.selectedOrderNumber,
          dealTitle: this.selectedDealTitle,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.success) {
          alert('Gift card use confirmed in-store!');
          this.updateOrderStatus(this.selectedOrderNumber, this.selectedDealTitle, true);
        } else {
          alert('Error confirming gift card use');
        }
      } catch (error) {
        console.error('Error confirming gift card use:', error);
        alert('Error confirming gift card use');
      } finally {
        this.closeModal();
      }
    },
    updateOrderStatus(orderNumber, dealTitle, redeemed) {
      const order = this.orders.find(order => order.order_id === orderNumber);
      if (order) {
        const item = order.items.find(item => item.dealTitle === dealTitle);
        if (item) {
          item.redeemed = redeemed;
        }
      }
    },
    closeModal() {
      this.showModal = false;
      this.showConfirmUseModal = false;
      this.showVerificationModal = false;
      this.showCodeModal = false;
      this.showRedeemInStoreModal = false;
    }
  }
};
</script>

<style scoped>
.orders-section {
  padding: 20px;
}

.orders-section h2 {
  margin-bottom: 20px;
  text-align: left;
}

.order-card {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 700px; /* Controls the width of the card */
  margin-left: 0; /* Align cards to the left */
}

.order-details {
  display: flex;
  flex-direction: column;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.order-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-info-row {
  margin-bottom: 10px;
}

.order-label {
  font-weight: bold;
  color: #777;
}

.order-value {
  font-size: 1em;
}

.order-status {
  font-weight: bold;
}

.order-status.Completed {
  color: green;
}

.order-status.Processing {
  color: orange;
}

.order-status.Cancelled {
  color: red;
}

.order-items {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.order-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-title {
  font-weight: bold;
  font-size: 15px;
}

.item-quantity {
  margin-left: 20px;
}

.gift-card-code {
  display: flex;
  align-items: center;
}

.gift-card-code button {
  margin-left: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
}

.gift-card-code button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.gift-card-code button:hover:enabled {
  background-color: #6E9F49;
}

.order-action-button {
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  align-self: flex-end;
}

.order-action-button:hover {
  background-color: #f0f0f0;
}

.load-more-button {
  display: block;
  margin-left: 25%;
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.load-more-button:hover {
  background-color: #6E9F49;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  width: 600px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.modal-section {
  margin-bottom: 20px;
  text-align: left;
}

.modal-section p {
  margin: 10px 0;
  font-size: 16px;
}

.barcode-container {
  margin: 20px 0;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.modal-content h3 {
  margin-bottom: 20px;
}

.modal-content button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.action-button {
  background-color: #4c6b30;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.action-button:hover {
  background-color: #6e9f49;
}

.secondary-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.secondary-button:hover {
  background-color: #e0e0e0;
}

.close-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.close-button:hover {
  background-color: #c82333;
}

.modal-content button:hover {
  background-color: #6E9F49;
}

.modal-content button:last-child {
  background-color: #dc3545;
}

.modal-content button:last-child:hover {
  background-color: #c82333;
}

@media(max-width: 1600px){
  .load-more-button{
    margin-left: 20%;
  }
}

@media (max-width: 1440px){
  .load-more-button{
    margin-left: 25%;
  } 
}

@media (max-width: 1200px){
  .load-more-button{
    margin-left: 35%;
  }
}

@media (max-width: 1024px){
  .load-more-button{
    margin-left: 40%;
  }
}

@media (max-width: 768px) {
  .order-card {
    padding: 1rem;
  }

  .order-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-action-button {
    margin-top: 10px;
    align-self: stretch;
  }

  .item-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-quantity {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .order-card {
    padding: 0.5rem;
    width: 300px;
  }

  .order-header {
    align-items: flex-start;
  }

  .order-info-row {
    margin-bottom: 5px;
  }

  .item-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-quantity {
    margin-left: 0;
    margin-top: 5px;
  }

  .gift-card-code button {
    padding: 5px;
  }

  .orders-section {
    padding: 0px;
  }

  .load-more-button{
    margin-left: 33%
  }

  .modal-content {
    padding: 1rem;
  }
  .modal-content h3 {
    font-size: 1.5rem;
  }
  .modal-content p {
    font-size: 1.2rem;
  }
  .modal-content button {
    font-size: 1.2rem;
    padding: 0.8rem 1.2rem;
  }
  .modal-content .button-group {
    flex-direction: column;
    gap: 10px;
  }
  .modal-content .button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
