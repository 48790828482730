<template>
  <div class="step-details">
    <h2>Step 1: Details</h2>
    <div class="form-group">
      <label for="businessSearch">Business</label>
      <input type="text" id="businessSearch" v-model="businessQuery" @input="searchBusiness" placeholder="Search for a business" />
      <ul v-if="businesses.length > 0" class="business-list">
        <li v-for="business in businesses" :key="business._id" @click="selectBusiness(business)">
          {{ business.business_name }}
        </li>
      </ul>
    </div>
    <div class="form-group">
      <label for="dealTitle">Gift Card Title</label>
      <input type="text" id="dealTitle" v-model="dealTitle" @input="validateForm" required />
    </div>
    <div class="form-group">
      <label for="dealDetails">About Gift Card</label>
      <div id="dealDetails" ref="dealDetailsEditor"></div>
    </div>
    <div class="form-group">
      <label for="dealFinePrint">Fine Print</label>
      <div id="dealFinePrint" ref="dealFinePrintEditor"></div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

export default {
  name: 'StepDetails',
  data() {
    return {
      dealTitle: '',
      dealDetails: '',
      dealFinePrint: '',
      businessQuery: '',
      businesses: [],
      quill: null,
      quillFinePrint: null,
      token: localStorage.getItem('token'),
      defaultFinePrint: `
        This gift card is valid for purchases in a single transaction or across multiple transactions until the full balance is used. It is non-transferable, cannot be redeemed for cash, and cannot be used to purchase other gift cards.
        This gift card cannot be combined with any other discounts, promotions, or special offers unless explicitly stated.
        The gift card must be presented at the time of purchase, and it cannot be applied to prior transactions.
        It is only valid for in-stock items and cannot be used on back-ordered or pre-ordered products.
        Additional restrictions may apply to specific product categories or services.
        The merchant reserves the right to refuse, modify, or cancel the gift card at any time, and it is void where prohibited by law.
        This gift card has no cash value and cannot be exchanged for cash or credit. Lost or stolen cards will not be replaced without proof of purchase. Unauthorized reproduction or resale of this gift card is strictly prohibited. Gift Card Expires 12 months from the date of purchase.
      `,
    };
  },
  methods: {
    validateForm() {
      const isValid =
        this.dealTitle.trim().length > 0 &&
        this.quill.root.innerHTML.trim().length > 0 &&
        this.quillFinePrint.root.innerHTML.trim().length > 0;

      this.$emit('formValidation', isValid);
    },
    validateAndSave() {
      this.dealDetails = this.quill.root.innerHTML;
      this.dealFinePrint = this.quillFinePrint.root.innerHTML;
      if (this.dealTitle && this.dealDetails && this.dealFinePrint) {
        this.saveDetails();
        this.$emit('nextStep');
      }
    },
    saveDetails() {
      const giftCardDetails = {
        dealTitle: this.dealTitle,
        dealDetails: this.dealDetails,
        dealFinePrint: this.dealFinePrint,
      };
      localStorage.setItem('giftCardDetails', JSON.stringify(giftCardDetails));
    },
    setDetails(details) {
      this.dealTitle = details.dealTitle;
      this.dealDetails = details.dealDetails;
      this.dealFinePrint = details.dealFinePrint;
      this.quill.root.innerHTML = this.dealDetails;
      this.quillFinePrint.root.innerHTML = this.dealFinePrint || this.defaultFinePrint;
    },
    async searchBusiness() {
      if (this.businessQuery.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/search/business`, {
            params: { query: this.businessQuery },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
          this.businesses = response.data.businesses;
        } catch (error) {
          console.error('Error searching for businesses:', error);
        }
      } else {
        this.businesses = [];
      }
    },
    selectBusiness(business) {
      localStorage.setItem('currentBusiness', business._id);
      this.businessQuery = business.business_name;
      this.businesses = [];
    },
  },
  mounted() {
    this.quill = new Quill(this.$refs.dealDetailsEditor, {
      theme: 'snow',
      placeholder: 'Describe the deal...',
      modules: {
        toolbar: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }],
          [{ 'align': [] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    this.quillFinePrint = new Quill(this.$refs.dealFinePrintEditor, {
      theme: 'snow',
      placeholder: 'Add fine print...',
      modules: {
        toolbar: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }],
          [{ 'align': [] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    const savedDetails = JSON.parse(localStorage.getItem('giftCardDetails'));
    if (savedDetails) {
      this.setDetails(savedDetails);
    } else {
      this.quillFinePrint.root.innerHTML = this.defaultFinePrint;
    }

    this.$emit('formValidation', this.dealTitle && this.dealDetails);

    this.quill.on('text-change', () => {
      this.dealDetails = this.quill.root.innerHTML;
      this.validateForm();
    });

    this.quillFinePrint.on('text-change', () => {
      this.dealFinePrint = this.quillFinePrint.root.innerHTML;
      this.validateForm();
    });
  },
  watch: {
    dealTitle() {
      this.validateForm();
    },
    dealDetails() {
      this.validateForm();
    },
    dealFinePrint() {
      this.validateForm();
    },
  },
};
</script>

<style scoped>
.step-details {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

#dealDetails,
#dealFinePrint {
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

#dealDetails:focus,
#dealFinePrint:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

.business-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.business-list li {
  padding: 10px;
  cursor: pointer;
}

business-list li:hover {
  background-color: #f0f0f0;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .form-group {
    margin-bottom: 15px;
  }

  input,
  select,
  textarea {
    padding: 8px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 180px;
  }

  .business-list li {
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea {
    padding: 6px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 160px;
  }

  .business-list li {
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .form-group {
    margin-bottom: 5px;
  }

  input,
  select,
  textarea {
    padding: 5px;
  }

  #dealDetails,
  #dealFinePrint {
    height: 140px;
  }

  .business-list li {
    padding: 5px;
  }
}
</style>